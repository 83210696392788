@import 'variables.scss';
.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: $backgroundColor;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $primary;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.grid-container{
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
	grid-gap: 0.5em;
}

.timeHeaderControl {
  cursor: pointer;
  color: grey;
  font-size: small;
}

.timeHeader {
  color: grey;
  font-size: small;
  text-align: right;
}

tr > th {
  cursor: default;
}

.timeHeaderColumn {
  min-width: 100px;
}
